import Layout from '@components/layout/Layout';
import Container from '@components/ui/container/Container';
import Footer from '@components/ui/footer/Footer';
import Header from '@components/ui/header/Header';
import HighlightsList from '@components/ui/highlights-list/HighlightsList';
import TitleBar from '@components/ui/title-bar/TitleBar';
import LanguageProvider from '@lib/language.provider';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const FuzjePage = ({ pageContext, location }) => {
	i18n.activate(pageContext.language);

	const data = [
		t`Prawne due diligence przedmiotu transakcji w celu identyfikacji ryzyk.`,
		t`Audyty wewnętrzne przed transakcją w celu identyfikacji ryzyk prawnych i doradztwo w przeprowadzaniu działań naprawczych.`,
		t`Badanie warunków transakcji pod kątem wymogu uzyskania zgody na koncentrację oraz doradztwo w postępowaniach koncentracyjnych przed UOKiK.`,
		t`Organizacja data room i obsługa procesu Q&A w trakcie due dilligence.`,
		t`Przygotowywanie dokumentów transakcyjnych i negocjowanie umów M&A.`
	];

	const highlights = [
		{ label: t`Ponad 40`, description: t`zrealizowanych transakcji M&A` },
		{
			label: t`5`,
			description: t`przeprowadzonych likwidacji spółek kapitałowych`
		},
		{
			label: t`10`,
			description: t`przekształconych i 5 podzielonych spółek kapitałowych`
		},
		{ label: t`2`, description: t`zorganizowane data room` }
	];

	return (
		<LanguageProvider>
			<Layout>
				<main>
					<title>
						<Trans>Fuzje i przejęcia</Trans>
					</title>
					<Header location={location.pathname} />
					<div className="flex flex-col min-h-screen">
						<TitleBar>
							<div className="relative z-10 pt-10 w-full text-white md:max-w-md lg:max-w-2xl md:pr-10 lg:pr-20">
								<div className="text-sm uppercase">
									<Trans>Specjalizacje</Trans>
								</div>
								<h1 className="font-serif text-4xl font-bold">
									<Trans>Fuzje i przejęcia</Trans>
								</h1>
							</div>
							<div className="absolute top-0 left-0 w-full h-full">
								<StaticImage
									className="block object-cover w-full h-full"
									src="../../assets/images/fuzje-i-przejecia-photo-01.jpg"
									alt={t`Kontakt`}
									placeholder={'none'}
								/>
							</div>
						</TitleBar>
						<div>
							<Container>
								<div className="grid gap-8 lg:gap-16 py-8 md:grid-cols-2 md:py-12">
									<div className="text-gray-dark">
										<h2 className="mb-3 font-bold">Zakres usług</h2>
										<HighlightsList items={data} />
									</div>
									<div>
										<div className="grid grid-cols-2 gap-2 lg:gap-3">
											{highlights.map((item) => (
												<div className="p-4 lg:p-6 border border-gray-medium">
													<div className="w-full">
														<div className="mb-3 text-2xl font-bold">
															{item.label}
														</div>
														<div className="text-gray-dark text-sm">
															{item.description}
														</div>
													</div>
												</div>
											))}
										</div>
									</div>
								</div>
							</Container>
						</div>
						<Footer />
					</div>
				</main>
			</Layout>
		</LanguageProvider>
	);
};

export default FuzjePage;
